import React, { useState, useEffect } from 'react'
import { Bounce, ToastContainer, toast } from 'react-toastify'

import languageDataAlerts from '../../helpers/languageDataAlerts'
import {
	languageDataAuth,
	enDefaultRevsh,
} from '../../helpers/languageDataAuth'
import Deposit from '../Deposit'
import PinInput from '../Design/PinInput'
import Preloader from '../Design/Preloader'
import './index.scss'
import { promos } from '../../helpers/getPromo'

function Auth({ languageCode, userGeo, referral = 'leisure' }) {
	const [pinCode, setPinCode] = useState('')
	const [isChecked, setIsChecked] = useState(false)
	const [pinCheckSuccess, setPinCheckSuccess] = useState(false)
	referral = referral || 'leisure';

	const revshare = promos[referral]?.revshare
	const cpaAZ = promos[referral]?.cpaAZ
	const cpaIN = promos[referral]?.cpaIN

	const { userISO, userCountry } = userGeo

	const languageData =
		languageDataAuth[userISO] ||
		languageDataAuth[languageCode] ||
		enDefaultRevsh

	const languageDataAlert =
		languageDataAlerts[languageCode] || languageDataAlerts.en

	/* console.log("User country = ", userCountry, '\nUser country ISO-code = ', userISO)  */

	const handleCopy = () => {
		switch (userISO) {
			case 'AZ':
				navigator.clipboard.writeText(cpaAZ)
				break
			case 'IN':
				navigator.clipboard.writeText(cpaIN)
				break
			default:
				navigator.clipboard.writeText(revshare)
				break
		}
	}

	const handleChange = event => {
		setIsChecked(event.target.checked)
	}
	const pathnameParts = window.location.pathname.split('/')
	const tgUserId = pathnameParts[pathnameParts.length - 1]
	const handlePinComplete = async pin => {
		setPinCode(pin)

		const response = await fetch(
			`https://luckyjet.casinohack.site/api/users/${tgUserId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ user_id: pin }),
			}
		)

		const data = await response.json()

		if (data.user_data && data.user_data.user_id === pin) {
			setPinCheckSuccess(true)
		} else {
			toast.error(languageDataAlert.errorSign)
			setPinCheckSuccess(false)
		}
	}

	return pinCheckSuccess ? (
		<Deposit languageCode={languageCode} userGeo={userGeo} />
	) : (
		<div className='auth-container'>
			<ToastContainer theme='dark' />
			<div className='lucky-jet-design'>
				<Preloader />
			</div>
			<div className='auth-main'>
				<div className='auth-main__form'>
					<div className='form-top'>
						<h1 className='form-title'>
							{languageData.title1}
							<br />
							{languageData.title2}
						</h1>
						<h2 className='form-subtitle'>
							{languageData.subtitle1} <br />
							{languageData.subtitle2}
							<span>{`«${
									(userISO === 'AZ' && cpaAZ) ||
									(userISO === 'IN' && cpaIN) ||
									revshare
								}»`}</span>
						</h2>
						<div className='form-password'>
						<PinInput maxLength={9} onSubmit={handlePinComplete} />
						</div>
						<button className='form-button-for-copy-promo' onClick={handleCopy}>
							<div className='form-button-for-copy-promo__text'>
								{languageData.buttonCopy}
								<span>{`«${
									(userISO === 'AZ' && cpaAZ) ||
									(userISO === 'IN' && cpaIN) ||
									revshare
								}»`}</span>
							</div>
							<div className='form-button-for-copy-promo__icon' />
						</button>
					</div>
					<hr className='form-separator' />
					<div className='form-bottom'>
						<div className='form-checkbox'>
							<div type='checkbox' className='form-checkbox__input'>
								<div className='form-checkbox__input_true' />
							</div>
							<label className='form-checkbox__label'>
								<p>{languageData.checkboxLabel}</p>
								<a target='blank' href='https://telegra.ph/Policy-03-17'>
									{languageData.checkboxUrl}
								</a>
							</label>
						</div>
						<a
							target='blank'
							href='https://1wbapm.life/casino/list?open=register&p=lkna'
						>
							<button className='button-registration'>
								{languageData.buttonRegister}
								<div className='button-registration__arrow'>
									<div className='button-arrow' />
								</div>
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth
