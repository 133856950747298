import React, { useState } from 'react';
import './index.scss';

function PinInput({ maxLength, onSubmit }) {
  const [pin, setPin] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= maxLength) {
      setPin(value);
    }
  };

  const handleSubmit = () => {
    onSubmit(pin); // Вызов функции из пропсов
  };

  const isButtonDisabled = pin.length < 8 || pin.length > maxLength;

  return (
    <div className="container-pin">
      <input
        className="pin-input"
        type="text"
        value={pin}
        onChange={handleChange}
        maxLength={maxLength}
        inputMode="numeric"
        placeholder="1win ID"
        autoFocus
      />
      <button
        className="pin-button"
        disabled={isButtonDisabled}
        onClick={handleSubmit}
      >
        NEXT
      </button>
    </div>
  );
}

export default PinInput;